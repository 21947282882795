import React from "react"
import { Switch } from "@headlessui/react"

const getInitialTheme = _ => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("theme")
    if (typeof storedPrefs === "string") {
      return storedPrefs
    }
    const userMedia = window.matchMedia("(prefers-color-scheme: dark)")
    if (userMedia.matches) {
      return "dark"
    }
  }
  return "dark"
}

const setTheme = theme => {
  if (typeof window !== "undefined" && window.localStorage) {
    const root = window.document.documentElement
    const isDark = theme === "dark"
    root.classList.remove(isDark ? "light" : "dark")
    root.classList.add(theme)
    localStorage.setItem("theme", theme)
  }
}

function Toggle() {
  const [dark, setDark] = React.useState(getInitialTheme)
  React.useEffect(() => {
    setTheme(dark ? "dark" : "light")
  }, [dark])

  return (
    <div className="absolute top-4 right-4">
      <Switch
        checked={dark}
        onChange={setDark}
        className={`${
          dark ? "bg-indigo-200" : "bg-gray-600"
        } relative inline-flex items-center h-6 rounded-full w-11 border-2 border-transparent cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Enable {dark ? "dark" : "light"} mode</span>
        <span
          className={`${
            dark ? "translate-x-6" : "translate-x-1"
          } transform transition ease-in-out duration-200 inline-block w-4 h-4 bg-white rounded-full`}
        />
      </Switch>
    </div>
  )
}
export default Toggle
